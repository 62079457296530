<template>
    <div>
        <div class="login">
            <!-- <div style="margin-bottom: 40px">
                <img src="@/assets/img/logo.png" style="width: 100px"/>
            </div> -->
            <van-form @submit="onSubmit">
                <van-field
                        v-model="account"
                        name="用户名"
                        label="账户："
                        placeholder="请输入用户名"
                        style="margin-top:15px"
                        :rules="[{ required: true, message: '请填写用户名' }]"
                />
                <van-field
                        v-model="password"
                        type="password"
                        name="密码"
                        label="密码："
                        placeholder="请输入密码"
                       :rules="[{ required: true, message: '请填写密码' }]"
                />
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提 交</van-button>
                </div>
            </van-form>

            
        </div>
    </div>

</template>

<script>
    import { Toast} from 'vant';
    export default {
        name: 'Login',
        data() {
            return {
                account:'',
                password:''
            }
        },
        methods:{
            onSubmit(values) {
                let params = {account: this.account,password:this.password}
                if(!this.account||!this.password){
                    Toast.fail('账户名或者密码不能为空!');
                    return;
                }
                this.$api.loginByAccount(params).then(res=>{
                    if(res.data.code==0){
                        let d = res.data.data;
                        // localStorage.setItem("accessToken",d.token.accessToken);
                        localStorage.setItem("uuid",d.uuid);
                        let smsSend = localStorage.getItem("smsSend");
                        if(smsSend == "1"){
                            this.$router.push({name:'smsSend'});
                        }else{
                            this.$router.push({name:'Home'});
                        }
                    }
                });
            },
        },
       
        created(){
            this.$store.commit('setPageTitle', "登录");
        }
    }
</script>

<style lang="scss">
    .login{
        width:100%;
        height:100%;
        background-size:cover;
        position:fixed;
        z-index:-10;}
    .welcome{
        width:100%;
        margin:25% 0;
    }
    .welcome img{
        width:100%;
    }
    .login-inp{
        margin:0 30px 15px 30px;
        border:1px solid #fff;
        border-radius:25px;
    }
    .login-inp label{
        width:4em;
        text-align:center;
        display:inline-block;
        color:#fff;
    }
    .login-inp input{
        line-height:40px;
        color:#fff;
        background-color:transparent;
        border:none;
        outline: none;
    }
    .login-inp a{
        display:block;
        width:100%;
        text-align:center;
        line-height:40px;
        color:#fff;
        font-size:16px;
        letter-spacing:5px;
    }
    .login-txt{
        text-align:center;
        color:#fff;
    }
    .login-txt a{
        color:#fff;
        padding:0 5px;
    }
    .system-title{
        font-family: 楷体;
        font-weight: bold;
        font-size: 25px;
    }
</style>
